import { view } from "@risingstack/react-easy-state";
import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Link,
  Button,
  Stack,
  Text,
  Box,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import invoice from "../../stores/invoice";
import { SomethingNotFound, UnknownError } from "../errors";

const Messages = view(function Messages() {
  if (invoice.OrderID.isFetching)
    return (
      <Text>
        Trying to find your order. Please wait, it may take a while...
      </Text>
    );

  if (invoice.OrderID.isReady)
    return (
      <Text>
        Ok, we have found your order and populated the fields below. You can
        change what you need and then click on the Send button to receive the
        invoice.
      </Text>
    );

  if (invoice.OrderID.isError)
    if (invoice.OrderID.error === "not found")
      return <SomethingNotFound name="Order ID" />;
    else return <UnknownError />;

  return null;
});

export default view(function OrderID() {
  return (
    <Stack
      as="form"
      spacing={4}
      onSubmit={(e) => {
        e.preventDefault();
        if (invoice.OrderID.isReady) invoice.reset();
        else invoice.OrderID.fetch();
      }}
    >
      <FormControl id="OrderID">
        <FormLabel>Enter your Order ID</FormLabel>
        <Input
          isDisabled={invoice.OrderID.isReady}
          maxW="sm"
          placeholder="Order ID"
          type="text"
          value={invoice.fields.OrderID}
          onChange={(e) => {
            invoice.OrderID.set(e.target.value);
          }}
        />
        <FormHelperText>
          You can find it in the subject of your purchase email. If you don't
          have access to that email anymore, you can ask for a new one in the{" "}
          <Link as={RouterLink} to="/download" fontWeight="bold">
            download page
          </Link>
          .
        </FormHelperText>
      </FormControl>
      <Box>
        <Button
          type="submit"
          isDisabled={!invoice.fields.OrderID || invoice.OrderID.isFetching}
        >
          {invoice.OrderID.isFetching
            ? "Finding your order..."
            : invoice.OrderID.isReady
            ? "Reset"
            : "Find my order"}
        </Button>
      </Box>
      <Messages />
    </Stack>
  );
});
