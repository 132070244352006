import { FC } from "react";
import {
  Box,
  Button as ChakraButton,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { IoIosMenu } from "react-icons/io";

const DesktopItem: FC<{ name: string; href: string }> = ({ name, href }) => (
  <Link
    href={href}
    py="4"
    px="8"
    isExternal
    _hover={{ bg: "tye.lightgreen", color: "white" }}
  >
    {name}
  </Link>
);

const DesktopMenu = () => (
  <Box display={["none", "none", "block"]}>
    <DesktopItem name="Home" href="https://www.trainyourears.com" />
    <DesktopItem name="Support" href="https://support.trainyourears.com" />
  </Box>
);

const MobileMenu = () => (
  <Box display={["block", "block", "none"]}>
    <Menu>
      <MenuButton
        as={ChakraButton}
        rightIcon={<IoIosMenu />}
        borderRadius="md"
        borderWidth="1px"
        borderColor="gray.300"
        color="gray.700"
        bg="web.lightergrey"
      >
        Menu
      </MenuButton>
      <MenuList>
        <MenuItem as={Link} href="https://www.trainyourears.com" isExternal>
          Home
        </MenuItem>
        <MenuItem as={Link} href="https://support.trainyourears.com" isExternal>
          Support
        </MenuItem>
      </MenuList>
    </Menu>
  </Box>
);

const Navigation = () => (
  <>
    <DesktopMenu />
    <MobileMenu />
  </>
);

export default Navigation;
