import { Text, Link } from "@chakra-ui/react";

export function SendEmail() {
  return (
    <>
      {" "}
      just{" "}
      <Link
        isExternal
        href="https://www.trainyourears.com/contact/"
        fontWeight="bold"
      >
        send us an email
      </Link>{" "}
      and we will help you out.
    </>
  );
}

export function EmailNotice() {
  return (
    <Text fontStyle="italic" color="web.grey">
      Please note that it may take a few minutes to reach your inbox. If you
      don't receive it after 5 minutes, please check your
      spam/updates/promotions folder. If the email is not there after that time,
      don't worry, just
      <SendEmail />
    </Text>
  );
}

export function UnknownError() {
  return (
    <Text color="red">
      Something went wrong. Please try it again and if the problem persist,
      <SendEmail />
    </Text>
  );
}

export function SomethingNotFound({ name }: { name: string }) {
  return (
    <Text color="red">
      Sorry, that {name} was not found. Please check it and try again and if the
      problem persist,
      <SendEmail />
    </Text>
  );
}
