import { extendTheme } from "@chakra-ui/react";

export default extendTheme({
  colors: {
    tye: {
      white: "#FFFFFF",
      lightergrey: "#ECF0F1",
      lightgrey: "#DBE2E6",
      grey: "#C7CFD4",
      darkgrey: "#B9C0C0",
      lightgreen: "#22C7AB",
      green: "#16AE98",
      blue: "#3A4F64",
      lightblue: "#425971",
    },
    web: {
      lightergrey: "#F4F4F4",
      lightgrey: "#C7C7C7",
      grey: "#575757",
      darkblue: "#2C424E",
      green: "#16AE98",
    },
  },
  styles: {
    global: {
      "html, body, #root": {
        height: "100%",
        minHeight: "100vh",
      },
    },
  },
  fonts: {
    heading: "Aleo",
  },
});
