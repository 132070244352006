import { Input, Button, VStack } from "@chakra-ui/react";
import { view } from "@risingstack/react-easy-state";
import download from "../../stores/download";

export default view(function Form() {
  return (
    <VStack
      as="form"
      alignSelf="center"
      spacing="4"
      onSubmit={(e) => {
        e.preventDefault();
        download.submit();
      }}
    >
      <Input
        minW={["300", "460"]}
        type="email"
        placeholder="Email – the one you used to purchase TrainYourEars"
        value={download.email}
        onChange={(e) => download.setEmail(e.target.value)}
      />
      <Button
        colorScheme="teal"
        type="submit"
        isDisabled={!download.email || download.isSending}
      >
        {download.isSending ? "Sending..." : "Send me the email"}
      </Button>
    </VStack>
  );
});
