import { Box, Image, Flex, Container } from "@chakra-ui/react";
import Logo from "../../assets/trainyourears-logo.png";
import Navigation from "./navigation";

const Header = () => (
  <Box bg="web.lightergrey" py="4">
    <Container maxWidth="container.lg">
      <Flex justify="space-between" align="center">
        <Image src={Logo} w={["200px", "334px"]} />
        <Navigation />
      </Flex>
    </Container>
  </Box>
);

export default Header;
