import { FC } from "react";
import { Stack, Heading } from "@chakra-ui/react";

const Title: FC<{ title: string; subtitle: string }> = ({
  title,
  subtitle,
}) => (
  <Stack spacing="4">
    <Heading textAlign="center" size="xl" color="web.grey">
      {title}
    </Heading>
    <Heading
      textAlign="center"
      color="web.lightgrey"
      size="md"
      fontWeight="medium"
    >
      {subtitle}
    </Heading>
  </Stack>
);

export default Title;
