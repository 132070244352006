import { Global, css } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={css`
      @font-face {
        font-family: "Aleo";
        src: url("${process.env.PUBLIC_URL}/aleo-bold-webfont.woff2")
          format("woff2");
        font-weight: 700;
        font-style: normal;
      }
    `}
  />
);

export default Fonts;
