import { Stack, Text } from "@chakra-ui/react";
import { SendEmail } from "../errors";

export default function Content() {
  return (
    <Stack spacing="4">
      <Text fontWeight="light" fontSize="lg">
        Enter your email and click on the send button. In a few minutes, you
        will receive an email with your License Key and Download Links.
      </Text>
      <Text
        fontWeight="light"
        color="gray.400"
        fontStyle="italic"
        fontSize="md"
        borderLeftWidth="thick"
        pl="4"
      >
        If you get a "No more installs available" error or you need to activate
        an offline computer, <SendEmail />{" "}
      </Text>
    </Stack>
  );
}
