import { Text, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import Title from "../title";

const ExpiredLinks = () => (
  <>
    <Title
      title="Expired Link!"
      subtitle="It seems like this link has expired."
    />
    <Text fontWeight="light" fontSize="lg" textAlign="center">
      Sorry for the issue. Please, visit the{" "}
      <Link
        as={RouterLink}
        to="/download"
        fontWeight="medium"
        color="web.green"
      >
        Download Page
      </Link>{" "}
      and enter your email again to receive new links.
    </Text>
  </>
);

export default ExpiredLinks;
