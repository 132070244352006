import { Stack, Text } from "@chakra-ui/react";
import { view } from "@risingstack/react-easy-state";
import download from "../../stores/download";
import { SomethingNotFound, EmailNotice, UnknownError } from "../errors";

function Sending() {
  return (
    <Text textAlign="center" color="web.green">
      Trying to send the email...
    </Text>
  );
}

function Sent() {
  return (
    <Stack textAlign="center">
      <Text>
        Succeed! We have sent you an email with your{" "}
        <strong>License Key</strong> and <strong>Download Links</strong> :)
      </Text>
      <EmailNotice />
    </Stack>
  );
}

export default view(function Messages() {
  if (download.isSending) return <Sending />;
  if (download.isError && download.error === "email not found")
    return <SomethingNotFound name="email" />;
  if (download.isError) return <UnknownError />;
  if (download.isSent) return <Sent />;
  return null;
});
