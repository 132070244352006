import { FC } from "react";
import {
  Box,
  Flex,
  Container,
  Heading,
  UnorderedList,
  ListItem,
  Link,
  Text,
} from "@chakra-ui/react";
import elements, { Element } from "./elements";

const Block: FC<{ element: Element }> = ({ element }) => (
  <Box color="white" py="10" px="5">
    <Heading size="md" pb="4">
      {element.name}
    </Heading>
    <UnorderedList spacing="2" pl="4">
      {element.items.map((item) => (
        <ListItem key={item.name}>
          <Link href={item.href} isExternal>
            {item.name}
          </Link>
        </ListItem>
      ))}
    </UnorderedList>
  </Box>
);

const Footer = () => (
  <Box bg="web.darkblue" py="4">
    <Container maxWidth="container.lg">
      <Flex justify="space-around" wrap="wrap">
        {elements.map((element) => (
          <Block key={element.name} element={element} />
        ))}
      </Flex>
      <Text textAlign="center" fontSize="lg" color="web.lightgrey" py="10">
        <b>TrainYourEars</b> – All rights reserved @ 2020
      </Text>
    </Container>
  </Box>
);

export default Footer;
