import Title from "../title";
import Content from "./Content";
import Form from "./Form";
import Messages from "./Messages";

export default function Download() {
  return (
    <>
      <Title
        title="License Key and Download Links"
        subtitle="Forgot your License Key? Need to download the software again?"
      />
      <Content />
      <Form />
      <Messages />
    </>
  );
}
