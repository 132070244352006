import {
  Text,
  Box,
  Button,
  FormHelperText,
  Stack,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { view } from "@risingstack/react-easy-state";
import invoice from "../../stores/invoice";
import { EmailNotice, UnknownError } from "../errors";

interface FieldProps {
  name: keyof typeof invoice["fields"];
  description?: string;
  isRequired?: boolean;
  type?: "text" | "email";
  label?: string;
  maxW?: string;
  isReadOnly?: boolean;
}

const Field = view(function Field({
  name,
  description,
  isRequired,
  type = "text",
  label,
  maxW = "sm",
  isReadOnly,
}: FieldProps) {
  return (
    <FormControl id={name} isRequired={isRequired}>
      <FormLabel>{label || name}</FormLabel>
      <Input
        ml={2}
        type={type}
        maxW={maxW}
        isReadOnly={isReadOnly}
        value={invoice.fields[name]}
        onChange={(e) => invoice.setField(name, e.target.value)}
      />
      {description && <FormHelperText ml={2}>{description}</FormHelperText>}
    </FormControl>
  );
});

export default view(function Form() {
  return (
    <Stack
      as="form"
      spacing={6}
      onSubmit={(e) => {
        e.preventDefault();
        invoice.PDF.submit();
      }}
    >
      <Field
        name="Name"
        label="Contact Name"
        isRequired={!invoice.fields.VatNumber}
      />
      <Field
        name="Organization"
        label="Company"
        description={`The name of your Company.${
          !invoice.fields.VatNumber ? " Optional." : ""
        }`}
        isRequired={!!invoice.fields.VatNumber}
      />
      <Field
        name="Address"
        label="Company Address"
        description={`The address of your company.${
          !invoice.fields.VatNumber ? " Optional." : ""
        }`}
        maxW="container.xl"
        isRequired={!!invoice.fields.VatNumber}
      />
      {invoice.fields.VatNumber && (
        <Field
          name="VatNumber"
          label="European VAT ID"
          description="This is the European VAT ID you used to purchase TrainYourEars. It can't be changed."
          isRequired
          isReadOnly
        />
      )}
      <Field
        name="Country"
        isRequired
        isReadOnly
        description="This is the country you used to purchase TrainYourEars. It can't be changed."
      />
      <Field
        name="Email"
        label="Send invoice to"
        type="email"
        description="Where do you want us to send the PDF invoice?"
        isRequired
      />
      <Box>
        <Button
          type="submit"
          colorScheme="teal"
          isDisabled={invoice.PDF.isSent || invoice.PDF.isSending}
        >
          {invoice.PDF.isSending
            ? "Sending..."
            : invoice.PDF.isSent
            ? "Sent!"
            : "Send me the invoice"}
        </Button>
      </Box>
      {invoice.PDF.isSending && (
        <Text>Sending the invoice to your email. Please wait...</Text>
      )}
      {invoice.PDF.isSent && (
        <>
          <Text>We have sent you an email with your invoice.</Text>
          <EmailNotice />
        </>
      )}
      {invoice.PDF.isError && <UnknownError />}
    </Stack>
  );
});
