import { store } from "@risingstack/react-easy-state";
import { byIso } from "country-code-lookup";

interface Invoice {
  fields: {
    Email: string;
    Name: string;
    Organization: string;
    Address: string;
    OrderID: string;
    Country: string;
    VatNumber: string;
  };

  setField(name: keyof Invoice["fields"], value: string): void;
  reset(): void;

  OrderID: {
    isFetching: boolean;
    isReady: boolean;
    isError: boolean;
    error: string;
    set(value: string): void;
    fetch(): Promise<void>;
  };

  PDF: {
    isSending: boolean;
    isSent: boolean;
    isError: boolean;
    submit(): Promise<void>;
  };
}

const initialOrder: Invoice["fields"] = {
  Email: "",
  Name: "",
  Organization: "",
  Address: "",
  OrderID: "",
  VatNumber: "",
  Country: "",
};

const invoice = store<Invoice>({
  fields: initialOrder,

  setField(name, value) {
    invoice.fields[name] = value;
  },

  reset() {
    invoice.fields = initialOrder;
    invoice.OrderID.isFetching = false;
    invoice.OrderID.isReady = false;
    invoice.OrderID.isError = false;
    invoice.OrderID.error = "";
    invoice.PDF.isSending = false;
    invoice.PDF.isSent = false;
    invoice.PDF.isError = false;
  },

  OrderID: {
    isFetching: false,
    isReady: false,
    isError: false,
    error: "",

    set(value) {
      invoice.fields.OrderID = value;
    },

    async fetch() {
      invoice.OrderID.isFetching = true;
      invoice.OrderID.isReady = false;
      invoice.OrderID.isError = false;
      invoice.OrderID.error = "";
      await new Promise((resolve) => setTimeout(resolve, 3000));
      try {
        const res = await fetch(
          "https://n8n.trainyourears.com/webhook/c3c8f4a0-1e74-41a3-92dd-d09a438a8bc5",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ OrderID: invoice.fields.OrderID }),
          }
        );
        if (res.status !== 200) throw new Error("not valid reponse");
        const json = await res.json();

        if (json.error === "not found") throw new Error("not found");

        invoice.fields.Email = json.Email;
        invoice.fields.Name = json.Name;
        invoice.fields.Organization =
          json.Organization !== "TrainYourEars" ? json.Organization : "";
        invoice.fields.Address = json.Address;
        invoice.fields.VatNumber = json.VatNumber;
        invoice.fields.Country = byIso(json.CountryCode)?.country || "US";

        invoice.OrderID.isReady = true;
      } catch (error) {
        invoice.OrderID.isError = true;
        invoice.OrderID.error = error.message;
      }
      invoice.OrderID.isFetching = false;
    },
  },

  PDF: {
    isSending: false,
    isSent: false,
    isError: false,

    async submit() {
      invoice.PDF.isSending = true;
      invoice.PDF.isSent = false;
      invoice.PDF.isError = false;
      await new Promise((resolve) => setTimeout(resolve, 3000));
      try {
        const res = await fetch(
          "https://n8n.trainyourears.com/webhook/34938172-e1fa-4322-965a-7bc4c000d8cd",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(invoice.fields),
          }
        );
        if (res.status !== 200) throw new Error("not valid reponse");
        const { sent, error } = await res.json();
        if (error) throw new Error(error);
        if (sent) invoice.PDF.isSent = true;
      } catch (error) {
        console.error(error);
        invoice.PDF.isError = true;
      }
      invoice.PDF.isSending = false;
    },
  },
});

export default invoice;
