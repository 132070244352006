import { view } from "@risingstack/react-easy-state";
import invoice from "../../stores/invoice";
import Title from "../title";
import OrderID from "./OrderID";
import Form from "./Form";

export default view(function Invoice() {
  return (
    <>
      <Title
        title="Genarate your invoice"
        subtitle="Did you buy TrainYourEars? Generate your invoice here."
      />
      <OrderID />
      {invoice.OrderID.isReady && <Form />}
    </>
  );
});
