import { ChakraProvider, Container, Stack, Flex, Text } from "@chakra-ui/react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import Download from "./download";
import ExpiredLinks from "./expired-links";
import theme from "../theme";
import Fonts from "./styles/fonts";
import Invoice from "./invoice";

const App = () => (
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <Fonts />
      <Flex direction="column" justify="space-between" minH="100vh">
        <Header />
        <Container maxWidth="container.md">
          <Stack py="20" spacing="10">
            <Switch>
              <Route path="/invoice">
                <Invoice />
              </Route>
              <Route path="/download">
                <Download />
              </Route>
              <Route path="/expired-links">
                <ExpiredLinks />
              </Route>
              <Route path="/">
                <Text align="center">Not found</Text>
              </Route>
            </Switch>
          </Stack>
        </Container>
        <Footer />
      </Flex>
    </ChakraProvider>
  </BrowserRouter>
);

export default App;
