import { store } from "@risingstack/react-easy-state";

interface Download {
  email: string;
  setEmail(email: string): void;
  isSending: boolean;
  isSent: boolean;
  isError: boolean;
  error: string;
  submit(): void;
}

const download = store<Download>({
  email: "",
  isSending: false,
  isSent: false,
  isError: false,
  error: "",

  setEmail: (email) => {
    download.email = email;
  },

  submit: async () => {
    download.isSending = true;
    download.isSent = false;
    download.isError = false;
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const res = await fetch(
        "https://n8n.trainyourears.com/webhook/f77cc0ab-368f-404e-aa08-ebaca687eab9",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: download.email }),
        }
      );
      const text = await res.text();
      if (text === "email not found") throw new Error("email not found");
      if (text !== "license sent") throw new Error("unknown problem");
      download.isSent = true;
    } catch (error) {
      download.isError = true;
      download.error = error.message;
      console.error(error);
    }
    download.isSending = false;
  },
});

export default download;
