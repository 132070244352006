export interface Element {
  name: string;
  items: {
    name: string;
    href: string;
  }[];
}

const footerElements: Element[] = [
  {
    name: "Get more info",
    items: [
      {
        name: "Documentation",
        href: "https://docs.trainyourears.com/",
      },
      {
        name: "Testimonials",
        href: "https://www.trainyourears.com/testimonials",
      },
      {
        name: "Videos",
        href: "https://www.youtube.com/user/TrainYourEars",
      },
      {
        name: "Screenshots",
        href: "https://www.trainyourears.com/#screenshots",
      },
    ],
  },
  {
    name: "Other sections",
    items: [
      {
        name: "FAQ",
        href: "https://support.trainyourears.com/",
      },
      {
        name: "Download Links",
        href: "https://admin.trainyourears.com/download",
      },
      {
        name: "Public Licenses",
        href: "https://www.trainyourears.com/public-use-licenses",
      },
      {
        name: "Affiliate Program",
        href: "https://www.trainyourears.com/become-an-affiliate",
      },
    ],
  },
  {
    name: "Contact with us",
    items: [
      {
        name: "Support",
        href: "https://support.trainyourears.com/",
      },
      {
        name: "Follow us on Twitter",
        href: "https://twitter.com/TrainYourEarsEN",
      },
      {
        name: "Like us on Facebook",
        href: "https://www.facebook.com/TrainYourEars",
      },
    ],
  },
  {
    name: "Legal stuff",
    items: [
      {
        name: "Terms & conditions",
        href: "https://www.trainyourears.com/terms-and-conditions",
      },
      {
        name: "Cookies",
        href: "https://www.trainyourears.com/cookies",
      },
      {
        name: "Privacy policy",
        href: "https://www.trainyourears.com/privacy-policy",
      },
      {
        name: "Refund policy",
        href: "https://www.trainyourears.com/return-policy",
      },
    ],
  },
];

export default footerElements;
